import axios from 'axios';

 

const apiClient = axios.create({
   //baseURL: 'http://localhost:8000',
   baseURL: 'https://referralsapi.diplomat.co.sz/public',

  withCredentials: true,

});

 

export default apiClient;