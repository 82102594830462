  import React, { useState, useEffect } from "react";
  import { styled } from "@mui/material/styles";
  import { List, ListItem, Chip, ListItemText } from "@mui/material";

  import Paper from "@mui/material/Paper";
  import { Button, Grid, TextField, Typography } from "@mui/material";
  import { useTimer } from "react-timer-hook";
  import TimerStyled from "../Timer";
  import { useFormik } from 'formik';
  import * as yup from 'yup';
  import axios from "axios";
  import apiClient from "../../apiClient";
  import { Navigate } from "react-router-dom";

  const client = axios.create({
      baseURL: "https://api.brevo.com/v3",
      headers: { "api-key": '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' },
  });

  const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      color: theme.palette.text.secondary,
  }));

  const validationSchema = yup.object({
      email: yup
          .string('Enter your email')
          .email('Enter a valid email')
          .required('Email is required')
  });

  const Demo = styled('div')(({ theme }) => ({
      backgroundColor: theme.palette.background.paper,
  }));

  export const useEffectOnce = (effect) => {
      const [needToCall, setNeedToCall] = React.useState(false);

      React.useEffect(() => {
          if (needToCall) {
              effect();
          }
          else {
              setNeedToCall(true);
          }
      }, [needToCall]);
  };

  export default function HomeLanding() {
      const [userEmailsArray, setUserEmailsArray] = useState([]);
      const [userEmailsArrayTemp, setUserEmailsArrayTemp] = useState([]);
      const [optedInCount, setOptedInCount] = useState(0);
      const [currentUser, setCurrentuser] = useState({});
      const [loggedIn, setLoggedIn] = useState(false);
      const [loading, setLoading] = useState(true);
      const [loadingLoggedIn, setLoadingLoggedIn] = useState(true);
      const [errorInEmail, setErrorInEmail] = useState(false);
      const [loadingEmail, setLoadingEmail] = useState(false);
      const [giftValue, setGiftValue] = useState(50.00);
      const expiryTimestamp = new Date();
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 1200000);
      const {
          seconds,
          minutes,
          hours,
          days,
      } = useTimer({
          expiryTimestamp,
          onExpire: () => console.warn("onExpire called"),
      });

      const formik = useFormik({
          initialValues: {
              email: ''
          },
          validationSchema: validationSchema,
          onSubmit: (values) => {
              // Check if Email Exists already in the Brevo database
              client.post("/contacts/doubleOptinConfirmation", { email: values.email, includeListIds: [98], templateId: 178, redirectionUrl: "https://referrals.diplomat.co.sz/", attributes: { "REFERED": JSON.parse(localStorage.getItem("User")) ? JSON.parse(localStorage.getItem("User")).email : "-" } })
                  .then((response) => {

                      var emailsArray = [];
                      var userTemp = JSON.parse(localStorage.getItem("User"));

                      if (userTemp.emails) {
                          emailsArray = (JSON.parse(localStorage.getItem("User")).emails).split(',');
                      }

                      emailsArray = [...emailsArray, values.email];

                      apiClient.get('/sanctum/csrf-cookie')
                          .then(response => {
                              apiClient.put(`/api/users/${JSON.parse(localStorage.getItem("User")).uuid}`,
                                  {
                                      ...userTemp, // Use the existing user data
                                      emails: emailsArray.join(),
                                      refered: userTemp.refered + 1, // Increment refered count
                                      wallet: Number(userTemp.wallet) + giftValue,
                                  },
                                  {
                                      headers: {
                                          Authorization: `Bearer ${localStorage.getItem("Token")}`,
                                          'Accept': 'application/json',
                                          'Content-Type': 'application/json',
                                      }
                                  }
                              )
                                  .then((res) => {
                                      // Update Local storage user
                                      alert(`Congratulations! The email has been submitted, as soon as your contact opts in, you will get Pts${giftValue}!`);
                                      localStorage.setItem('User', JSON.stringify(res.data.User));
                                      window.location.reload(); // Refresh the page
                                  })
                                  .catch((e) => {
                                      // Handle error
                                      console.error("Error updating user:", e);
                                  });
                          })
                          .catch((e) => console.log(e));
                  })
                  .catch((e) => {
                      alert("We have this email address, please try another one.");
                      // console.log("Error ->" + e);
                  });
          },
          validate: (values) => {
              let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

              if (regEmail.test(values.email)) {
                  // Start Loading
                  setLoadingEmail(true);

                  const storedUser = JSON.parse(localStorage.getItem("User"));

                  if (storedUser && storedUser.emails) { // Check if User exists and has emails
                      // Check if user submitted this email already
                      if (storedUser.emails.split(',').some(item => values.email === item)) {
                          setErrorInEmail(true);
                          setLoadingEmail(false);
                      } else {
                          setErrorInEmail(false);

                          // Check if Email Exists already in the Brevo database
                          client.get(`/contacts/${values.email}`)
                              .then((response) => {
                                  setErrorInEmail(true);
                                  setLoadingEmail(false);
                              })
                              .catch((e) => {
                                  setLoadingEmail(false);
                              });
                      }
                  } else {
                      // If User doesn't exist or has no emails, proceed without checking existing emails
                      setErrorInEmail(false);
                      setLoadingEmail(false);
                  }
              }
          }
      });
      
      useEffectOnce(() => {
        if (JSON.parse(localStorage.getItem("User"))) {
            apiClient
                .get(
                    `/api/users/${JSON.parse(localStorage.getItem("User")).uuid}`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    }
                )
                .then((res) => {
                    var currentUsr = res.data;
                    setLoggedIn(true);
                    setUserEmailsArray(res.data.emails.split(","));
                    setLoadingLoggedIn(false);
                    setLoading(false);
                    let optedInCount = 0; // Initialize optedInCount
                    res.data.emails.split(",").forEach((item) => {
                        client.get(`/contacts/${item}`)
                            .then((response) => {
                                if (!userEmailsArrayTemp.some(value => value.email === item)) {
                                    setUserEmailsArrayTemp((prevEmails) => {
                                        return [...prevEmails, { opted: true, email: item }]
                                    });
                                    optedInCount++; // Increment optedInCount for each opted-in email
                                    setOptedInCount(optedInCount);
                                    currentUsr = ({ ...currentUsr, wallet: optedInCount * giftValue }); // Calculate wallet value based on optedInCount
                                    setCurrentuser(currentUsr);
                                    setCurrentuser(currentUsr);
                                    console.log(optedInCount);
                      apiClient
                        .put(
                          `/api/users/${
                            JSON.parse(localStorage.getItem("User")).uuid
                          }`,
                          {
                            ...currentUsr,
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "Token",
                              )}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          },
                        )
                        .then((res) => {
                          console.log("Wallet value updated after user opted in");
                        })
                        .catch((error) => {
                          console.error("Error updating wallet value:", error);
                        });
                                }
                            })
                            .catch((e) => {
                                if (!userEmailsArrayTemp.some(value => value.email === item)) {
                                    setUserEmailsArrayTemp((prevEmails) => {
                                        return [...prevEmails, { opted: false, email: item }]
                                    });
                                }
                            })
                    });
                })
                .catch((e) => {
                    setLoggedIn(true);
                    setLoadingLoggedIn(false);
                    setLoading(false);
                    //console.log(optedInCount);
                });
        } else {
            setLoggedIn(false);
            setLoadingLoggedIn(false);
            setLoading(false);
        }
    }, []);
    

      return (
          <Grid container spacing={2} style={{ paddingTop: "50px", padding: '20px' }}>
              {loadingLoggedIn ? null : loggedIn ? null : <Navigate to='/guest' />}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant="h4" color={"white"}>Referrals Panel</Typography>
              </Grid>
              <Grid item xs={12}>
                  <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                          <Item>
                              <Typography variant="h2">{currentUser ? currentUser.refered : 0}</Typography>
                              <Typography variant="subtitle1">People referred</Typography>
                          </Item>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                          <Item>
                              <Typography variant="h2">{optedInCount}</Typography>
                              <Typography variant="subtitle1">People referred & opted in</Typography>
                          </Item>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                          <Item>
                              <Typography variant="h2">Pts{currentUser ? currentUser.wallet : 0.00}</Typography>
                              <Typography variant="subtitle1">Credit Available</Typography>
                          </Item>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                          <Item>
                              <Typography variant="h2">Pts0.00</Typography>
                              <Typography variant="subtitle1">Credit Claimed</Typography>
                          </Item>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                  <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                          <Item>
                              <Typography variant="h1">
                                  <span className="subtitle1">Pts</span>{giftValue}
                              </Typography>
                              <Typography variant="h4" sx={{ mb: 1 }}>
                                  on every successful referral
                              </Typography>
                              <TimerStyled seconds={seconds} minutes={minutes} hours={hours} days={days} />
                          </Item>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <Item>
                              <form onSubmit={formik.handleSubmit}>
                                  <Grid container spacing={2}>
                                      <Grid item xs={8}>
                                          <TextField
                                              fullWidth
                                              id="email"
                                              name="email"
                                              label="Email"
                                              size="small"
                                              value={formik.values.email}
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              error={formik.touched.email && Boolean(formik.errors.email)}
                                              helperText={formik.touched.email && formik.errors.email}
                                          />
                                      </Grid>
                                      <Grid item xs={4}>
                                          <Button disabled={loadingEmail || errorInEmail} style={{ height: '40px' }} color="primary" variant="contained" fullWidth type="submit">
                                              Add Email
                                          </Button>
                                      </Grid>
                                  </Grid>
                              </form>
                              <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                      <Typography sx={{ mt: 4, mb: 1 }} variant="h6" component="div">
                                          Registered Emails
                                      </Typography>
                                      <Demo>
                                          <div className="maxHeight: '200px', overflowY: 'scroll">
                                              <List dense={true}>
                                                  {userEmailsArrayTemp.map((item) => (
                                                      <ListItem key={item.email}>
                                                          <Chip label={item.opted ? "Opted-In" : "Waiting for Opt-In"} color={item.opted ? "success" : "info"} style={{ marginRight: '10px' }} />
                                                          <ListItemText primary={item.email} />
                                                      </ListItem>
                                                  ))}
                                              </List>
                                          </div>
                                      </Demo>
                                  </Grid>
                              </Grid>
                          </Item>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
      );
  }
