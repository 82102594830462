import React, { useState } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import "./style.css";
import { Alert, Snackbar, TextField } from "@mui/material";
import apiClient from '../apiClient';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Home from '@mui/icons-material/Home';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
});

export default function ForgotPassword(){
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            apiClient.post('/api/forgot-password', values)
                .then((res) => {
                    setError(false);
                    setErrorMessage('');
                    alert("Password reset link sent to your email");
                    // Handle success, e.g., show a message to the user
                    // window.location.reload();
                })
                .catch((e) => {
                    console.log("Error" + e);
                    setError(true);
                    setErrorMessage("Failed to send password reset link");
                });
        }
    });

    return (
        <div className="forgot-password">
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Fab color="primary" aria-label="add">
                        <Home />
                    </Fab>
                </Link>
            </Box>
            <div className="form">
                <form onSubmit={formik.handleSubmit}>
                    <span>Forgot Password</span>
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        sx={{ mt: 2 }}
                    />
                    <button type="submit">Send Password Reset Link</button>
                </form>
            </div>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}
