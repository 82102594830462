import React, { useEffect, useState } from "react";
import Header from "../components/Navbar";
import HomeLanding from "../components/HomeLanding";
import { Navigate } from "react-router-dom";

export default function Home() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Check if there is a token in localStorage
        if (localStorage.getItem('Token')) {
            setLoggedIn(true);
        }
        // Set loading to false after the check is done
        setLoading(false);
    }, []);
    

    return (
        <>
            {!loggedIn && !loading ? <Navigate to='/Guest'/> : null}
            <Header defaultActiveKey="/"/>
            <HomeLanding />
        </>
    );
}
