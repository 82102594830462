import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import logo from '../../assets/logo/Swazisat_Logo@2x.png';
import apiClient from '../../apiClient';
import { Button } from '@mui/material';

function Header(props) {

  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("Token")); // Set to true if there is a token in localStorage

  const handleLogout = () => {
    apiClient
      .post(
        "/api/logout",
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      )
      .then((res) => {
        localStorage.clear();
        setLoggedIn(false);
        window.location.href = "/guest"; // Redirect to the sign-in page
      })
      .catch((e) => {
        console.log(e);
      });
  };
  

  return (
    <Navbar sticky="top" expand="lg" className="bg-body-tertiary" bg="light" data-bs-theme="light">
      <Container>
        <Navbar.Brand href="/">
            {/* <img
              src={logo}
              width="170"
              className="d-inline-block align-top"
              alt="SwaziSat"
              style={{position: 'absolute', top: -10}}
            /> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto" variant="underline" defaultActiveKey={props.defaultActiveKey}>
            {!loggedIn && (
              <Nav.Link href="/signin"><Button variant='contained'>Log in/Sign up</Button></Nav.Link>
            )}
            {loggedIn && (
              <>
                <Navbar.Text>
                  Signed in as: <a href="/signin" className="text-decoration-none">{JSON.parse(localStorage.getItem("User")) ? JSON.parse(localStorage.getItem("User")).name : "User"}</a>
                </Navbar.Text>
                <Button variant='contained' onClick={handleLogout}>Log Out</Button>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
