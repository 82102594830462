import React, { useState } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import "./style.css";
import { Alert, Snackbar, TextField } from "@mui/material";
import apiClient from '../apiClient';
import { Link, Navigate } from "react-router-dom";
// import LoadingButton from '@mui/lab/LoadingButton';
//import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Home from '@mui/icons-material/Home';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(8, "Password must be at least 8 characters")
        .required("Password is required")
  });

export default function SignIn(){
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [redirect, setRedirect] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            apiClient.get('/sanctum/csrf-cookie')
                .then(response => {
                    apiClient.post('/api/signin', 
                        values,
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            }
                        }
                        )
                        .then((res) => {
                            setError(false);
                            setErrorMessage('');
                            localStorage.setItem('Token', res.data.token);
                            localStorage.setItem('User', JSON.stringify(res.data.user));
                            setLoading(false);
                            setRedirect(true);
                        })
                        .catch((e) => {
                            console.log("Error" + e);
                            setError(true);
                            setErrorMessage("Invalid Email/Password");
                            setLoading(false);
                        });
                })
                .catch((e) => console.log(e));
        }
      });



    return (
        <div className="login">
            {redirect ? <Navigate to='/'/> : null}

                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                         <Fab color="primary" aria-label="add">
                                <Home />
                         </Fab>
                    </Link>
                </Box>
        



            <div className="form">
                <form onSubmit={formik.handleSubmit}>
                    <span>Sign In</span>

                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        sx={{ mt: 2 }}
                      />

                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        sx={{ mt: 2 }}
                      />
                     <p style={{ marginTop: "10px"}}>
                            <a href="/forgotpassword" style={{ textDecoration: "none" }}>
                                forgot password
                            </a>
                    </p>
                    <p style={{marginTop: "10px"}}>Don't have an account? <a href="/signup">Sign Up</a></p>
                    <button type="submit">Sign In</button>
                    {/* <LoadingButton
                        onClick={formik.handleSubmit}
                        loading={loading}
                        loadingIndicator="Loading..."
                        variant="contained"
                        fullWidth
                        color="primary"
                    >
                        Sign In
                    </LoadingButton> */}
                </form>
            </div>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}