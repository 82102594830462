import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 3px; /* Decreased margin */
`;

const Title = styled.span`
  font-size: 10px; /* Decreased font size */
  margin-bottom: 3px; /* Decreased margin */
`;

const DigitContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
`;

const SingleDigit = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; /* Decreased font size */
  background-color: #404549;
  border-radius: 3px; /* Decreased border radius */
  padding: 5px 8px; /* Decreased padding */
  color: white;
  margin: 0 1px; /* Decreased margin */
`;

export default function Digit({ value, title }) {
  const leftDigit = value >= 10 ? value.toString()[0] : '0';
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
  return (
    <Container>
      <Title>{title}</Title>
      <DigitContainer>
        <SingleDigit>{leftDigit}</SingleDigit>
        <SingleDigit>{rightDigit}</SingleDigit>
      </DigitContainer>
    </Container>
  );
}
