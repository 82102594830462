import React, { useState } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import "./style.css";
import { Alert, Snackbar, TextField } from "@mui/material";
import apiClient from "../apiClient";
import {Link, Navigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Home from '@mui/icons-material/Home';
import { useParams } from 'react-router-dom';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    password_confirmation: yup
        .string('Confirm your password')
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required')
});

export default function ResetPassword() {
    const {token} = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [redirect, setRedirect] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            password_confirmation: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            apiClient.post(`/api/reset-password/${token}`, values)
                .then((res) => {
                    setError(false);
                    setErrorMessage('');
                    alert("Password reset successful");
                    window.location.reload();
                    setLoading(false);
                    setRedirect(true);
                })
                .catch((e) => {
                    console.log("Error ->" + e);
                    setError(true);
                    setErrorMessage("Something went wrong, please try again.");
                    setLoading(false);
                });
        }
    });

    return (
        <div className="reset-password">
            {redirect ? <Navigate to='/' /> : null}

            <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Fab color="primary" aria-label="add">
                        <Home />
                    </Fab>
                </Link>
            </Box>

            <div className="form">
                <form onSubmit={formik.handleSubmit}>
                    <span>Reset Password</span>

                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        sx={{ mt: 2 }}
                    />

                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        sx={{ mt: 2 }}
                    />

                    <TextField
                        fullWidth
                        id="password_confirmation"
                        name="password_confirmation"
                        label="Confirm Password"
                        type="password"
                        value={formik.values.password_confirmation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                        helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                        sx={{ mt: 2 }}
                    />

                    <button type="submit">Reset Password</button>
                </form>
            </div>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}
