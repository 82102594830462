import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Button, Chip, Grid, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { useTimer } from "react-timer-hook";
import TimerStyled from "../Timer";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import apiClient from "../../apiClient";
import { Navigate } from "react-router-dom";

const client = axios.create({
    baseURL: "https://api.brevo.com/v3",
      headers: { "api-key": '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' },
    });

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "center",
  color: theme.palette.text.secondary,
}));

const validationSchema = yup.object({
    email1: yup
      .string('Enter your first email')
      .email('Enter a valid email')
      .required('Email is required'),
    email2: yup
      .string('Enter your second email')
      .email('Enter a valid email')
      .notRequired('Email is required'),
    email3: yup
      .string('Enter your third email')
      .email('Enter a valid email')
      .notRequired('Email is required'),
  });

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

export const useEffectOnce = ( effect )=> {
    const [needToCall, setNeedToCall] = React.useState(false);

    React.useEffect(()=> {
        if (needToCall) {
            effect();
        }
        else {
            setNeedToCall(true);
        }
    }, [needToCall]);
};

export default function HomeLanding() {
    const [userEmailsArray, setUserEmailsArray] = useState([]); 
    const [userEmailsArrayTemp, setUserEmailsArrayTemp] = useState([]);
    const [optedInCount, setOptedInCount] = useState(0); 
    const [currentUser, setCurrentuser] = useState({}); 
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingLoggedIn, setLoadingLoggedIn] = useState(true); 
    const [errorInEmail, setErrorInEmail] = useState(false);
    const [errorInEmailMessage, setErrorInEmailMessage] = useState("Error");
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [giftValue, setGiftValue] = useState(50.00);
    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 1200000);
    const {
        seconds,
        minutes,
        hours,
        days,
    } = useTimer({
        expiryTimestamp,
        onExpire: () => console.warn("onExpire called"),
    });
    
    const formik = useFormik({
      initialValues: {
        email1: "",
        email2: "",
        email3: "",
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        let emailsArray = [];
        // Extract non-empty emails from the form values
        for (let i = 1; i <= 3; i++) {
          if (values[`email${i}`].trim() !== "") {
            emailsArray.push(values[`email${i}`]);
          }
        }
        if (emailsArray.length === 0) {
          alert("Please enter at least one email.");
          return;
        }
        
        let submissionCount = 0;
        for (const email of emailsArray) {
          try {
            await client.post("/contacts/doubleOptinConfirmation", {
              email: email,
              includeListIds: [98],
              templateId: 178,
              redirectionUrl: "https://referrals.diplomat.co.sz/",
              attributes: {
                REFERED: JSON.parse(localStorage.getItem("User")) ? JSON.parse(localStorage.getItem("User")).email : "-",
              },
            });
            submissionCount++;
            if (submissionCount === emailsArray.length) {
              alert(`Thanks for your submition !.`);
              window.location.reload(); // Refresh the page
            }
          } catch (error) {
            alert("Error: " + error.message);
          }
        }
      }
  });
   
      useEffectOnce(() => {
          if (JSON.parse(localStorage.getItem("User"))) {
              setLoggedIn(true);
          } else {
              setLoggedIn(false);
          }
      }, []);

  return (
    
    <Grid container spacing={2} style={{ paddingTop: "50px", padding: '20px' }}>
    {loadingLoggedIn ? null : loggedIn ? null : <Navigate to='/guest'/>}
    <Grid item xs={12} style={{textAlign: 'center'}}>
      <Typography variant="h4" color={"white"}>Referrals Panel</Typography>
       {/* {console.log("loggedIn:", loggedIn)};  */}
    </Grid>
    <Grid item xs={12} className="py-4"><Grid container spacing={2}></Grid><Item>To refer emails to our products and services enter emails below </Item></Grid>

    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Item>
            <Typography variant="h1">
              <span className="subtitle1">Pts</span>{giftValue}
            </Typography>
            <Typography variant="h4" sx={{ mb: 1 }}>
              on every successful referral
            </Typography>
          
            <TimerStyled seconds={seconds} minutes={minutes} hours={hours} days={days} />
            
            
          </Item>
        </Grid>
        <Grid item xs={12} sm={6}>
        
          <Item>
            
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                {[1, 2, 3].map((index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        id={`email${index}`}
                        name={`email${index}`}
                        label={`Email ${index}`}
                        size="small"
                        value={formik.values[`email${index}`]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched[`email${index}`] && Boolean(formik.errors[`email${index}`])}
                        helperText={formik.touched[`email${index}`] && formik.errors[`email${index}`]}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={4}>
                  <Button disabled={loadingEmail || errorInEmail} style={{ height: '40px' }} color="primary" variant="contained" fullWidth type="submit">
                    Add Emails
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ mt: 4, mb: 1 }} variant="h6" component="div">
                <p><a href="/signup" className="text-decoration-none">Create an account </a> to earn and track your points</p>
                </Typography>
                <Demo>
                  <div className="maxHeight: '200px', overflowY: 'scroll">

                  <List dense={true}>
                    {userEmailsArrayTemp.map((item) => (
                      <ListItem key={item.email}>
                        <Chip label={item.opted ? "Opted-In" : "Waiting for Opt-In"} color={item.opted ? "success" : "info"} style={{ marginRight: '10px' }} />
                        <ListItemText primary={item.email} />
                      </ListItem>
                    ))}
                  </List>

                  </div>
                  
                </Demo>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </Grid>

  


  </Grid>
  
  );
}
